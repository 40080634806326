.grid-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px;
}

.grid-item {
  flex: 1;
  flex-basis: 33.33%;
  max-width: 100%;
  margin-bottom: 30px;
  padding: 0 15px;
}

.p-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.p-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.p-card-img-container {
  position: relative;
  width: 100%;
  margin-bottom: 18px;
  overflow: hidden;
  border-bottom: 1px solid #e1e3e4;
}

.p-card-img {
  text-align: center;
}
.p-card-img img {
}

.p-card-info {
}

@media (min-width: 576px) {
  .grid-item {
    flex-basis: 50%;
    max-width: 25%;
  }
}
@media (min-width: 992px) {
  .grid-item {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 1200px) {
  .grid-item {
    flex-basis: 25%;
    max-width: 25%;
  }
}
