html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
  font-size: 10px;
}

/* Navbar section */

.myNav {
  width: 100%;
  height: 111px;
  position: fixed;
  line-height: 65px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 2;
}

.navContain {
  max-width: 1120px;
  margin: 0 auto;
}
.myNav div.logo {
  float: left;
  width: auto;
  height: auto;
  padding-left: 3rem;
}

.myNav div.logo a {
  text-decoration: none;
  color: #fff;
  font-size: 2.5rem;
}

.myNav div.logo a:hover {
  color: #00e676;
}

.myNav div.main_list {
  height: 65px;
  float: right;
}

.myNav div.main_list ul {
  width: 100%;
  height: 65px;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.myNav div.main_list ul li {
  width: auto;
  height: 65px;
  padding: 0;
  padding-right: 3rem;
}

.myNav div.main_list ul li a {
  text-decoration: none;
  color: #fff;
  line-height: 65px;
  font-size: 2.4rem;
}

.myNav div.main_list ul li a:hover {
  color: #000;
}
.affix div.main_list ul li a:hover {
  color: #f47ef3;
}

/* Home section */

.home {
  width: 100%;
  height: 80vh;

  background-image: radial-gradient(
    circle at 50% -20.71%,
    #abffff 0,
    #325fa3 50%,
    #16253e 100%
  );
  background-position: center top;
  background-size: cover;
}

.navTrigger {
  display: none;
}

/* Media qurey section */

@media screen and (max-width: 637px) {
  .navTrigger {
    display: block;
  }
  .myNav div.logo {
    margin-left: 15px;
  }
  .myNav div.main_list {
    width: 100%;
    height: 0;
    overflow: hidden;
    display: block;
    height: auto;
    background: #111;
    padding-top: 20px;
  }

  .myNav div.main_list ul {
    flex-direction: column;
    width: 100%;
    height: 100vh;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #111;
    /*same background color of navbar*/
    background-position: center top;
  }
  .myNav div.main_list ul li {
    text-align: right;
  }
  .myNav div.main_list ul li a {
    text-align: center;
    width: 100%;
    font-size: 3rem;
    padding: 20px;
  }
  .myNav div.media_button {
    display: block;
  }
}

/* Animation */
/* Inspiration taken from Dicson https://codemyui.com/simple-hamburger-menu-x-mark-animation/ */

.navTrigger {
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
}

.navTrigger i {
  background-color: #fff;
  border-radius: 2px;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
}

.navTrigger i:nth-child(1) {
  -webkit-animation: outT 0.8s backwards;
  animation: outT 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navTrigger i:nth-child(2) {
  margin: 5px 0;
  -webkit-animation: outM 0.8s backwards;
  animation: outM 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navTrigger i:nth-child(3) {
  -webkit-animation: outBtm 0.8s backwards;
  animation: outBtm 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
}

@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

.affix {
  padding: 0;
  height: 71px;
  background-color: #111;
}

.myH2 {
  text-align: center;
  font-size: 4rem;
}
.myP {
  text-align: justify;
  padding-left: 15%;
  padding-right: 15%;
  font-size: 20px;
}
@media all and (max-width: 700px) {
  .myP {
    padding: 2%;
  }
}
.Order {
  border: 1px;
  border-radius: 5px;
  padding: 3px;
  border-style: groove;
}

/* NAVIGATION */

nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all 0.5s;
}

/* stroke */
nav.stroke ul li a {
  position: relative;
  padding-bottom: 5px;
}
nav.stroke ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #000;
  height: 2px;
}

nav.stroke ul li a:hover:after {
  width: 100%;
}

/*shadowStroke*/
nav.shadowStroke ul li a {
  position: relative;
  padding-bottom: 5px;
}
nav.shadowStroke ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #f47ef3;
  height: 2px;
}

nav.shadowStroke ul li a:hover:after {
  width: 100%;
}
