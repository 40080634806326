.banner-container {
  background: url("../assets/images/Banner-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-height {
  max-height: 1400px;
  align-items: center;
  margin: 0;
  text-align: center;
}

.banner-bottle {
  width: 35%;
  max-width: 220px;
  min-width: 110px;
}

.banner-separator {
  padding: 5rem;
}

.banner-button {
  box-shadow: 2px 2px 12px -4px #000000;
  background-color: #ffffff;
  border-radius: 42px;
  display: inline-block;
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  padding: 17px 17px;
  text-decoration: none;
  text-shadow: 1px 1px 18px #ffffff;
  z-index: -3;
}

.banner-button:hover {
  text-decoration: none;
  color: #000000;
}

@media screen and (max-width: 575px) {
  .banner-height {
    align-items: center;
    margin: 0;
    text-align: center;
  }
  .banner-bottle {
    min-width: 140px;
  }
  .banner-header {
    font-size: 5rem;
  }
  .banner-sub {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 350px) {
  .banner-bottle {
    min-width: 110px;
  }
}

.button-text {
  z-index: 2;
  transition: 0.5s ease-in-out;
}

.btn-1 {
  color: #000;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 150px;
  margin: 1rem auto;
}

.btn-1:before {
  content: "";
  position: absolute;
  top: 0;
  right: -50px;
  bottom: 0;
  left: 0;
  border-right: 50px solid transparent;
  border-bottom: 80px solid #000;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: 0.5s ease-in-out;
  z-index: -1;
}

.btn-1:hover {
  color: #fff;
}

.btn-1:hover:before {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
