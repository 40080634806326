.main-footer {
  coler: white;
  background-color: #000;
  padding-top: 3em;
  position: relative;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}
.ul {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ul-li {
  list-style: none;
  display: inline;
}
.ul-li a {
  background: #000;
  height: 50px;
  text-align: center;
  line-height: 80x;
  font-size: 3rem;
  margin: 0 1px;
  display: inline-table;

  position: relative;
  overflow: hidden;
  position: relative;
  color: #fff;
  transition: 0.5s;
}

.space {
  padding: 0px 10px;
}
.Li-text {
  font-size: 1rem;
}

.instalogo a:hover {
  color: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  transform: translate(0, -5px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.twitterlogo a:hover {
  color: #00acee;
  transform: translate(0, -5px);
}
.whatslogo a:hover {
  color: #00ff00;
  transform: translate(0, -5px);
}
