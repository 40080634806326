.content-sperator {
  padding: 50px;
}

.trans {
  transition: all 0.3s ease;
}

.fa-chevron-right:hover {
  transform: scale(1.3);
}

.fa-chevron-left:hover {
  transform: scale(1.3);
}

.carouselContainer {
  width: 60%;
  padding-bottom: 50px;
  margin: 0 auto;
}

.carousel-img {
  max-width: 150px;
  max-height: 200px;
}

@media screen and (max-width: 575px) {
  .carouselContainer {
    width: 100%;
  }
  .carousel-img {
    max-width: 100px;
    max-height: 80px;
  }
}
