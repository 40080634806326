body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
  font-size: 10px;
}

/* Navbar section */

.myNav {
  width: 100%;
  height: 111px;
  position: fixed;
  line-height: 65px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 0.4s ease;
  z-index: 2;
}

.navContain {
  max-width: 1120px;
  margin: 0 auto;
}
.myNav div.logo {
  float: left;
  width: auto;
  height: auto;
  padding-left: 3rem;
}

.myNav div.logo a {
  text-decoration: none;
  color: #fff;
  font-size: 2.5rem;
}

.myNav div.logo a:hover {
  color: #00e676;
}

.myNav div.main_list {
  height: 65px;
  float: right;
}

.myNav div.main_list ul {
  width: 100%;
  height: 65px;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.myNav div.main_list ul li {
  width: auto;
  height: 65px;
  padding: 0;
  padding-right: 3rem;
}

.myNav div.main_list ul li a {
  text-decoration: none;
  color: #fff;
  line-height: 65px;
  font-size: 2.4rem;
}

.myNav div.main_list ul li a:hover {
  color: #000;
}
.affix div.main_list ul li a:hover {
  color: #f47ef3;
}

/* Home section */

.home {
  width: 100%;
  height: 80vh;

  background-image: radial-gradient(
    circle at 50% -20.71%,
    #abffff 0,
    #325fa3 50%,
    #16253e 100%
  );
  background-position: center top;
  background-size: cover;
}

.navTrigger {
  display: none;
}

/* Media qurey section */

@media screen and (max-width: 637px) {
  .navTrigger {
    display: block;
  }
  .myNav div.logo {
    margin-left: 15px;
  }
  .myNav div.main_list {
    width: 100%;
    height: 0;
    overflow: hidden;
    display: block;
    height: auto;
    background: #111;
    padding-top: 20px;
  }

  .myNav div.main_list ul {
    flex-direction: column;
    width: 100%;
    height: 100vh;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #111;
    /*same background color of navbar*/
    background-position: center top;
  }
  .myNav div.main_list ul li {
    text-align: right;
  }
  .myNav div.main_list ul li a {
    text-align: center;
    width: 100%;
    font-size: 3rem;
    padding: 20px;
  }
  .myNav div.media_button {
    display: block;
  }
}

/* Animation */
/* Inspiration taken from Dicson https://codemyui.com/simple-hamburger-menu-x-mark-animation/ */

.navTrigger {
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
}

.navTrigger i {
  background-color: #fff;
  border-radius: 2px;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
}

.navTrigger i:nth-child(1) {
  -webkit-animation: outT 0.8s backwards;
  animation: outT 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navTrigger i:nth-child(2) {
  margin: 5px 0;
  -webkit-animation: outM 0.8s backwards;
  animation: outM 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navTrigger i:nth-child(3) {
  -webkit-animation: outBtm 0.8s backwards;
  animation: outBtm 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
}

@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
            transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
            transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
            transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
            transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
            transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
            transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
            transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
            transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
            transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
            transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
            transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
            transform: translateY(-9px) rotate(135deg);
  }
}

.affix {
  padding: 0;
  height: 71px;
  background-color: #111;
}

.myH2 {
  text-align: center;
  font-size: 4rem;
}
.myP {
  text-align: justify;
  padding-left: 15%;
  padding-right: 15%;
  font-size: 20px;
}
@media all and (max-width: 700px) {
  .myP {
    padding: 2%;
  }
}
.Order {
  border: 1px;
  border-radius: 5px;
  padding: 3px;
  border-style: groove;
}

/* NAVIGATION */

nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all 0.5s;
}

/* stroke */
nav.stroke ul li a {
  position: relative;
  padding-bottom: 5px;
}
nav.stroke ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #000;
  height: 2px;
}

nav.stroke ul li a:hover:after {
  width: 100%;
}

/*shadowStroke*/
nav.shadowStroke ul li a {
  position: relative;
  padding-bottom: 5px;
}
nav.shadowStroke ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #f47ef3;
  height: 2px;
}

nav.shadowStroke ul li a:hover:after {
  width: 100%;
}

.banner-container {
  background: url(/static/media/Banner-2.3347e793.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-height {
  max-height: 1400px;
  align-items: center;
  margin: 0;
  text-align: center;
}

.banner-bottle {
  width: 35%;
  max-width: 220px;
  min-width: 110px;
}

.banner-separator {
  padding: 5rem;
}

.banner-button {
  box-shadow: 2px 2px 12px -4px #000000;
  background-color: #ffffff;
  border-radius: 42px;
  display: inline-block;
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  padding: 17px 17px;
  text-decoration: none;
  text-shadow: 1px 1px 18px #ffffff;
  z-index: -3;
}

.banner-button:hover {
  text-decoration: none;
  color: #000000;
}

@media screen and (max-width: 575px) {
  .banner-height {
    align-items: center;
    margin: 0;
    text-align: center;
  }
  .banner-bottle {
    min-width: 140px;
  }
  .banner-header {
    font-size: 5rem;
  }
  .banner-sub {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 350px) {
  .banner-bottle {
    min-width: 110px;
  }
}

.button-text {
  z-index: 2;
  transition: 0.5s ease-in-out;
}

.btn-1 {
  color: #000;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 150px;
  margin: 1rem auto;
}

.btn-1:before {
  content: "";
  position: absolute;
  top: 0;
  right: -50px;
  bottom: 0;
  left: 0;
  border-right: 50px solid transparent;
  border-bottom: 80px solid #000;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: 0.5s ease-in-out;
  z-index: -1;
}

.btn-1:hover {
  color: #fff;
}

.btn-1:hover:before {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.grid-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px;
}

.grid-item {
  flex: 1 1;
  flex-basis: 33.33%;
  max-width: 100%;
  margin-bottom: 30px;
  padding: 0 15px;
}

.p-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.p-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.p-card-img-container {
  position: relative;
  width: 100%;
  margin-bottom: 18px;
  overflow: hidden;
  border-bottom: 1px solid #e1e3e4;
}

.p-card-img {
  text-align: center;
}
.p-card-img img {
}

.p-card-info {
}

@media (min-width: 576px) {
  .grid-item {
    flex-basis: 50%;
    max-width: 25%;
  }
}
@media (min-width: 992px) {
  .grid-item {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 1200px) {
  .grid-item {
    flex-basis: 25%;
    max-width: 25%;
  }
}

.swiper-wrapper {
  max-width: 1400px;
  max-height: 900px;
  margin: 0 auto;
  padding: 100px 0px;
}

.BrainhubCarouselItem img {
  opacity: 0.5;
  transition: all 0.4s ease;
}
.BrainhubCarouselItem--active img {
  opacity: 1;
  transition: all 0.4s ease;
}

.main-footer {
  coler: white;
  background-color: #000;
  padding-top: 3em;
  position: relative;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}
.ul {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ul-li {
  list-style: none;
  display: inline;
}
.ul-li a {
  background: #000;
  height: 50px;
  text-align: center;
  line-height: 80x;
  font-size: 3rem;
  margin: 0 1px;
  display: inline-table;

  position: relative;
  overflow: hidden;
  position: relative;
  color: #fff;
  transition: 0.5s;
}

.space {
  padding: 0px 10px;
}
.Li-text {
  font-size: 1rem;
}

.instalogo a:hover {
  color: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-transform: translate(0, -5px);
          transform: translate(0, -5px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.twitterlogo a:hover {
  color: #00acee;
  -webkit-transform: translate(0, -5px);
          transform: translate(0, -5px);
}
.whatslogo a:hover {
  color: #00ff00;
  -webkit-transform: translate(0, -5px);
          transform: translate(0, -5px);
}

.content-sperator {
  padding: 50px;
}

.trans {
  transition: all 0.3s ease;
}

.fa-chevron-right:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.fa-chevron-left:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.carouselContainer {
  width: 60%;
  padding-bottom: 50px;
  margin: 0 auto;
}

.carousel-img {
  max-width: 150px;
  max-height: 200px;
}

@media screen and (max-width: 575px) {
  .carouselContainer {
    width: 100%;
  }
  .carousel-img {
    max-width: 100px;
    max-height: 80px;
  }
}

.swiper-wrapper {
  max-width: 1400px;
  max-height: 900px;
  margin: 0 auto;
  padding: 100px 0px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.page-containeer {
  display: flex;
  flex-direction: colmun;
  min-height: 100vh;
}

.content-wrap {
  flex: 1 1;
}

.loading {
  opacity: 1;
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

