.swiper-wrapper {
  max-width: 1400px;
  max-height: 900px;
  margin: 0 auto;
  padding: 100px 0px;
}

.BrainhubCarouselItem img {
  opacity: 0.5;
  transition: all 0.4s ease;
}
.BrainhubCarouselItem--active img {
  opacity: 1;
  transition: all 0.4s ease;
}
